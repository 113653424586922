@font-face {
  font-family: "Gatch";
  src: url("/assets/fonts/Gatch-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Planar";
  src: url("/assets/fonts/GT-Planar-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kibitz";
  src: url("/assets/fonts/kibitz-light-pro.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
