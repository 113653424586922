.editor

  @apply font-planar text-base md:text-lg tracking-wide

  &.editor-small
    @apply text-sm md:text-base

    h2,
    h3,
    h4
      @apply text-sm md:text-base uppercase tracking-wider
      @apply pt-3 md:pt-4 mt-3 md:mt-4 border-t

    a
      @apply no-underline

  &.editor-small-first
    h2:first-child,
    h3:first-child,
    h4:first-child
      @apply mt-0 md:mt-0

  h1,
  h2,
  h3,
  h4
    @apply text-sm md:text-base
    @apply uppercase tracking-wider
    @apply mb-[0.5em]


  p ~ h1,
  p ~ h2,
  p ~ h3,
  p ~ h4
    @apply mt-[2em]

  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0

  a
    @apply underline decoration-1
    @apply underline-offset-[2px] hover:underline-offset-[4px]
    @apply md:underline-offset-[3px] hover:md:underline-offset-[5px]

  // ol,
  // ul
  //   @apply max-w-3xl mx-auto text-left

  ul
    li
      @apply mb-3 pl-8 lg:pl-10 relative
      &:before
        content: '— '
        @apply absolute left-0
