.linkteaser
  @apply cursor-pointer

  &:hover
    @apply cursor-pointer
    .linkteaser--img
      @apply scale-105

    // &--body


    // &--link
