.artists-grid
  .tns-ovh
    overflow: visible !important
  .slider-wrapper
    @apply flex lg:grid
    &.tns-carousel
      @apply block lg:grid
      overflow: visible !important
  // .flickity-viewport
  //   @apply overflow-visible
  // .flickity-slider
  //   // @apply flex gap-4 md:gap-16


  @screen lg
    .artist-teaser
      @apply relative
      @apply before:lg:content-[''] before:lg:absolute before:lg:top-3 before:lg:left-3 before:lg:right-3 before:lg:bottom-3
      @apply before:bg-c-bg before:transition-colors before:duration-500
      img
        @apply relative transition-opacity duration-500

    &:hover
      .artist-teaser
        img
          @apply opacity-30
        &:hover
          img
            @apply opacity-100


.artists-hide
  @apply opacity-100 transition-opacity duration-500
  .artists-active &
    @apply opacity-30
